import {MatDialogModule} from "@angular/material/dialog";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {LoadingBarModule} from "@ngx-loading-bar/core";
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import {LoadingBarRouterModule} from "@ngx-loading-bar/router";
import {NgxCaptchaModule} from 'ngx-captcha';
import {ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SelectivePreloadingStrategyService} from "./selective-preloading-strategy.service";
import {SnackbarComponent} from "./shared/components/snackbar/snackbar.component";
import {HttpConfigInterceptor} from "./shared/interceptors/httpconfig.interceptor";
import {RequestInfoInterceptor} from "./shared/interceptors/request-info.interceptor";
import {DrugGroupModalComponent} from './layout/menu/drug-group-modal/drug-group-modal.component';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  declarations: [
    AppComponent,
    SnackbarComponent,
    DrugGroupModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatProgressBarModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    MatDialogModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [
    SelectivePreloadingStrategyService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInfoInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
