import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpRequest} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";

import {IDrugBaseInfo, IDrugOverview} from "../interfaces/drug-overview";
import {IPotentialSignalsData} from "../interfaces/potential-signal.interface";
import {IPsiFilter} from "../interfaces/psi-filter.interface";
import { FormGroup } from '@angular/forms';
import { IEvdasSignalData } from '../interfaces/evdas-signal.interface';


/** 
Purpose: To make Http calls for drug-overview components
Version History :
Date              Name             -      Reason for modification
25-Aug-2022     Pankaj Pal                IVPSD-3172  
06-Dec-2022     Naresh Ginjupalli         IVPSD-4550
20-Dec-2023     Pankaj Pal                IVPSD-5479
03-Apr-2024     Pankaj Pal                IVPSD-4550
29-Apr-2024     Naresh Ginjupalli         IVPSD-5866
13-Sep-2024     Pankaj Pal                IVPSD-7169
*/

@Injectable({
  providedIn: 'root'
})
export class DrugRequestService {
  public currentBaseInfo$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentBaseInfo = this.currentBaseInfo$.asObservable();

  public currentOverview$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentOverview = this.currentOverview$.asObservable();

  public currentSignals$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentSignals = this.currentSignals$.asObservable();

  public currentEvdasSignals$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentEvdasSignals = this.currentEvdasSignals$.asObservable();

  public excluded$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public excluded = this.excluded$.asObservable();

  public attachments: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public attachments$: Observable<any> = this.attachments.asObservable();

    // IVPSD-5479
    public timePeriod: BehaviorSubject<any> = new BehaviorSubject<any>('maxPeriod');
    public timePeriod$: Observable<any> = this.timePeriod.asObservable();
  

  constructor(private http: HttpClient) {
  }

  public getDrugOverview(filter): Observable<IDrugOverview> {
    return this.http.post<IDrugOverview>(`${environment.baseUrl}/api/v1/drugs/overview`,
      filter).pipe(
        map((overview) => {
          this.currentOverview$.next(overview);
          return overview
        })
    )
  }

  public getDrugBaseInfo(filter,drugGroupId): Observable<IDrugBaseInfo> {
    return this.http.post<IDrugBaseInfo>(`${environment.baseUrl}/api/v1/drugs/info?drugGroupId=${drugGroupId}`,
      filter).pipe(
        map((info) => {
          this.currentBaseInfo$.next(info);
          return info
        })
    )
  }

  public getSignals(
    drugGroupId: number,
    params: string,
    psiFilter: IPsiFilter,
      // IVPSD-5479
      timePeriod
  ): Observable<IPotentialSignalsData> {
    return this.http.post<IPotentialSignalsData>(`${environment.baseUrl}/api/v1/psi?drugGroupId=${drugGroupId}&${params}&timePeriod=${timePeriod}`, psiFilter).pipe(
      map((signals) => {
        this.currentSignals$.next(signals);
        return signals
      })
    )
  }

  //IVPSD-3172 changes to display message if data is not present for any of the datasource
  public verifyDataForTenantDataSources(psiFilter: IPsiFilter){
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.post<any>(`${environment.baseUrl}/api/v1/psi/tenant/info`,psiFilter); 
  }

  // IVPSD-4550 - Implemented new Services for drug attachments
  // IVPSD-4550 - Added DataSourceType parameter to support EVDAS file upload
  public uploadAttachment(files: File[],  dataSourceType:string,docAttachmentModel): Observable<any> {
    let formData: FormData = new FormData();
    for (let file of files) {
      formData.append('file', file); 
      formData.append("docAttachmentModel", new Blob([JSON.stringify(docAttachmentModel)], {
            type: "application/json"
        }));
    }
    const req = new HttpRequest('POST', `${environment.baseUrl}/api/v1/drugs/drug/attachments/upload?dataSourceType=${dataSourceType}`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  // IVPSD-4550 -Params added to support pagination
  public getAttachments(drugGroupId: number,params: string, dataSource: string): Observable<any> {
    return this.http
      .get(`${environment.baseUrl}/api/v1/drugs/drug/attachments?drugGroupId=${drugGroupId}&dataSource=${dataSource}&${params}`)
      .pipe(
        map((response) => {
          this.attachments.next(response);
          return response;
        })
      );
}

// IVPSD-4550 Added dataSourceType 
  public downloadAttachment(fileId: number, dataSourceType:string, bucket:string): void {
    
    window.location.href=`${environment.baseUrl}/api/v1/drugs/attachments/file/download?fileId=${fileId}&dataSource=${dataSourceType}&bucket=${bucket}`
  }

  // IVPSD-4550- datasource added to delete files for different datasources
  public deleteFile(fileId: string, dataSource:string,bucket:string): Observable<any> {
    return this.http.delete(`${environment.baseUrl}/api/v1/drugs/attachments/file?fileId=${fileId}&dataSource=${dataSource}&bucket=${bucket}`).pipe(
      map(() => {
         const attachments = this.attachments.getValue();
         const filteredAttachments = attachments.content.filter(item => item.id !== fileId);
         this.attachments.next(filteredAttachments);
      })
    );
  }

  public getSignalsWithinFrequency(
    drugGroupId: number,
    psiFilter: IPsiFilter
  ): Observable<IPotentialSignalsData> {
    return this.http.post<IPotentialSignalsData>(`${environment.baseUrl}/api/v1/psi/datefrequency?drugGroupId=${drugGroupId}`, psiFilter).pipe(
      map((signals) => {
       // this.currentSignals$.next(signals);
        return signals
      })
    )
  }

  // IVPSD-4550
  public getFilesByName(drugGroupId:number,searchString:string,params){
    return this.http
    .get(`${environment.baseUrl}/api/v1/drugs/drug/attachments/search?drugGroupId=${drugGroupId}&searchText=${searchString}&${params}`)
    .pipe(
      map((response) => {
        this.attachments.next(response);
        return response;
      })
    );
  }
  public getEvdasSignals( 
    drugGroupId: number,   
    params: string,
    psiFilter: IPsiFilter
  ): Observable<IPotentialSignalsData> {
    return this.http.post<IPotentialSignalsData>(`${environment.baseUrl}/api/v1/psi/evdas?drugGroupId=${drugGroupId}&${params}`, psiFilter).pipe(
      map((signals) => {
        this.currentEvdasSignals$.next(signals);
        return signals
      })
    )
  }

  public getEvdasSignalsDownload(    
    psiFilter: IPsiFilter
  ): Observable<IEvdasSignalData> {
    return this.http.post<IEvdasSignalData>(`${environment.baseUrl}/api/v1/psi/evdas/download`, psiFilter).pipe(
      map((signals) => {
       // this.currentSignals$.next(signals);
        return signals
      })
    )
  }

  // IVPSD-7169
  public checkDuplicateEvdasFile(fileName:string){
    return this.http.get(`${environment.baseUrl}/api/v1/drugs/drug/attachments/isDuplicate?fileName=${fileName}`).toPromise();

  }

}
